import { Component, OnInit, AfterViewInit, Renderer2, OnDestroy, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import {Observable, Subject, distinctUntilChanged, takeUntil} from 'rxjs'
import { Router } from '@angular/router'
import { trigger, style, animate, transition, } from '@angular/animations';
import { AppService } from '../services/app.service';
import { SafeResourceUrl,DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'quote-form',
    templateUrl: './quote-form.component.html',
    styleUrls: ['./quote-form.component.scss'],
    animations: [
      trigger(
        'menu',
        [
          transition(
            ':enter',
            [
              style({
                 opacity: 0,
                 transform: 'translateX(100%)'
              }),
              animate(
                '0.5s ease-out',
                style({
                    opacity: 1,
                    transform: 'translateX(0%)'
                })
              )
            ]
          ),
          transition(
            ':leave',
            [
              style({
                opacity: 1,
                transform: 'translateX(0%)'
              }),
              animate(
                '0.5s ease-out',
                style({
                    opacity: 0,
                    transform: 'translateX(100%)'
                })
              )
            ]
          )
        ]),
        trigger(
          'loading',[
            transition(":enter",[
              style({
                opacity: 0
              }),
              animate('0.5s linear',style({
                opacity: 1
              }))
            ]),
            transition(":leave",[
              style({
                opacity: 1
              }),
              animate('0.5s linear',style({
                opacity: 0
              }))
            ])
          ]
        )
    ]
})

export class QuoteComponent implements OnInit,AfterViewInit,OnDestroy{
  @ViewChild('iframeForm', {static: false}) quoteForm:ElementRef | undefined;
    breakpoint$:Observable<object>
    destroyed$ = new Subject<boolean>();
    type:string;
    host:string;
    link:SafeResourceUrl;
    linkLoaded:boolean = false
    dataUrl:string;
    iframeLoaded: boolean = false

    constructor(
        private renderer: Renderer2,
        private hostService: AppService,
        private sanitaze: DomSanitizer,
        private cd:ChangeDetectorRef
    ){}
    ngOnInit() : void{
      this.host = this.hostService.getQuoteFormHost()
      this.link = this.sanitaze.bypassSecurityTrustResourceUrl(`${this.host}/quote-form?source=C2C&referenceId=9ce7ce8df2&sourceUrl=https://coasttocoastauto.com/&onsuccess=thankyou`)
      this.linkLoaded = true
      this.dataUrl = `${this.host}/quote-form/`
      this.renderer.removeClass(document.body,'black-blocked')
      this.cd.detectChanges()
    }

    ngAfterViewInit(): void {
      setTimeout(() => {
        this.quoteForm ? this.quoteForm.nativeElement.style = '' : undefined
      },1200)
    }
    
    iframeLoadedHandle(e):void{
      this.iframeLoaded = true
    }

    ngOnDestroy(): void {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
}