import { AppService } from '../services/app.service';
import { Component, HostListener, OnInit, Renderer2, AfterViewInit,
  OnDestroy, HostBinding, ViewChild, ElementRef, ChangeDetectorRef
} from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router'
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import { BreakpointObserver } from '@angular/cdk/layout';
import {
  menu,header,stats,map,service,careers,reviews,footer,
  headerElements,statsElements,mapElements,faqs,faqsElements,serviceElements,careersElements,
  reviewsElements,footerElements,mapArrow,fadeOutIn,serviceFadeOutIn,mobileServiceToggl,
  desktopServiceToggl, faqsCardFade
} from './landing.animations'
import { distinctUntilChanged,Subject,takeUntil,fromEvent,take, Observable} from 'rxjs'
import { SafeResourceUrl,DomSanitizer } from '@angular/platform-browser';

// declare var $: any;
@Component({
  // standalone: true,
  selector: 'landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
  animations: [
    menu,header, stats, map, service, careers, reviews, footer,
    headerElements,statsElements, mapElements,faqs,faqsElements,serviceElements,careersElements,
    reviewsElements,footerElements,mapArrow, fadeOutIn,serviceFadeOutIn,mobileServiceToggl,
    desktopServiceToggl, faqsCardFade
  ],
  
})

export class LandingComponent implements OnInit, AfterViewInit, OnDestroy{
  

  @ViewChild('question',{static: false})
  questionInput:ElementRef | undefined
  @ViewChild('map',{static: false})
  map:ElementRef | undefined
  @ViewChild('videoConteiner',{static: false})
  mapConteiner: ElementRef | undefined
  @ViewChild("iframeForm",{static: false})
  quoteForm: ElementRef | undefined
  @ViewChild("serviceSection",{static:false})
  serviceSection: ElementRef | undefined

  swiper: Swiper | undefined | string = undefined
  year = new Date().getFullYear()

  breakpoint$:Observable<object>;
  destroyed$ = new Subject<boolean>();
  type:string = 'mobile'

  loading:boolean = true

  messageContent: string = ''

  // fastViewHide:boolean;
  arrowRotation:'horizontal' | 'vertical';

  host:string;
  link:SafeResourceUrl = '';
  linkLoaded:boolean = false
  dataUrl:string;

  menuShown:boolean = false

  selectedSection:string = 'none'

  selectedServices:string = 'DOOR-TO-DOOR'
  animationGoing:boolean = true;
  @HostBinding('@.disabled')
  public disableAnimations:boolean = false;

  touchStartY:number;
  // isDragging:boolean = false;
  startX:number;
  startY:number; scrollLeft:number; scrollTop:number;

  openedFaq: string = ''

  sectionArray:string[] = ['header','stats','map','service','faqs','careers','reviews','footer']
  selectedSections:string[] = []
  sectionIndex:number = 0
  // touchDevice:boolean = false
  supportsVideo:boolean = true
  touchedOnce:boolean
  params

  constructor(
    private breakpointObserver: BreakpointObserver,
    private renderer: Renderer2, 
    private router: Router,
    private service: AppService,
    private sanitaze: DomSanitizer,
    private cdr:ChangeDetectorRef,
    private route: ActivatedRoute
    ){}

  ngOnInit():void{
    // alert(`Your width: ${window.innerWidth}, height: ${window.innerHeight}`)
    // Getting host href for quote form
    this.host = this.service.getQuoteFormHost()
    // clearing link for quote form. bypassSecurityTrustResourceUrl is required if link is provided using [src] attr
    // Also adding timeout to quote-form start loading because quote-form script is downloading 2.5 Mb what will slow page loading speed
    setTimeout(() => {
      this.link = this.sanitaze.bypassSecurityTrustResourceUrl(`${this.host}/quote-form/?&source=C2C&referenceId=9ce7ce8df2&sourceUrl=https://coasttocoastauto.com/&onsuccess=thankyou`)
      this.linkLoaded = true
      // Removing styles added by other resources
    if(["desktop","mac"].includes(this.type)){
      setTimeout(() => {
        this.quoteForm ? this.quoteForm.nativeElement.style = '' : undefined;
      },1500)
    }
    },3000)
    
    this.dataUrl = `${this.host}/quote-form/`
    // Adding overflow:hidden and backgroundColor: black to body using renderer
    this.renderer.addClass(document.body,'black-blocked')
    // For autoplay map video, because if user didn`t interact with page, it won`t play
    fromEvent(document,'click').pipe(
      take(1)
    ).subscribe(() => {
      this.touchedOnce = true
    })
    // initialize adaptive type resize observer
    this.breakpoint$ = this.breakpointObserver
    .observe(['(min-width: 768px)','(min-width: 1024px)','(min-width: 800px)','(min-width: 1280px)','(min-width: 1280px) and (min-height: 832px), (min-width: 1400px)','(min-width: 1400px) and (min-height:900px)','(min-width: 1920px)'])
    .pipe(
      distinctUntilChanged(),
      takeUntil(this.destroyed$)
    );
    this.breakpoint$.pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(() =>this.resizeBreakpointsHandler());

    // Page is always opening at very top, previous scroll positions(if it was) are ignored
    if (history.scrollRestoration) {
      history.scrollRestoration = 'manual';
    } else {
      window.onbeforeunload = function () {
          window.scrollTo(0, 0);
      }
    }

    // Checking if browser supports video tag, if not playing it using img tag
    if(typeof document.createElement('video').canPlayType === 'function' && !!document.createElement('video').canPlayType){
      this.supportsVideo = true
      // alert("Your device does support video")
    }else{
      this.supportsVideo = false
      // alert("Your device does not support video")
    } 


    this.route.queryParams
      .subscribe(params => {
        this.params = params
      }
    );
  }
  serviceTStart:number
  serviceMStartX:number | null
  serviceMStartY:number | null
  mapTStart:number;
  touchedDownOnce: boolean = false;
  touchedUpOnce: boolean = false
  showArrow:boolean;

  // serviceMargins:string;
  ngAfterViewInit():void{
     // Loading careers bg later than other to provide header load faster
     setTimeout(() => {
      document.querySelector('.careers') ? document.querySelector('.careers').classList.add('careers-image') : undefined
    },3000)

    // Logic for map playing and scrolling actions(togglingSections)
    // if(!(['desktop,macxl'].includes(this.type))){
      let mapWrapper = document.getElementById('map-wrapper')
      fromEvent(mapWrapper, 'touchstart').pipe(
        takeUntil(this.destroyed$)
      ).subscribe((e:TouchEvent) => {
        this.mapTStart = e.touches[0].clientY;
      })
      
      fromEvent(mapWrapper, 'touchend').pipe(
        takeUntil(this.destroyed$)
      ).subscribe((e:TouchEvent) => {
        let mapTEnd = e.changedTouches[0].clientY
  
        if (this.mapTStart-20 > mapTEnd) {
          // User swiped down
          (mapWrapper.scrollHeight - mapWrapper.clientHeight <= mapWrapper.scrollTop + 100) ? this.showArrow = true : this.touchedDownOnce = true
          this.touchedUpOnce = false
        }
        else if (this.mapTStart+30 < mapTEnd) {
          // User swiped up
          if(this.touchedUpOnce && mapWrapper.scrollTop <= 0){
            this.showPreviousSection()
            this.touchedUpOnce = false
          }else{
            this.touchedUpOnce = true
          }
          this.touchedDownOnce = false;
          this.showArrow = false
        }
      })
    // }
    
    // Logic for toggling service options with touch devices
    let serviceWrapper = document.getElementById('tape-conteiner')
    if(serviceWrapper){
      fromEvent(serviceWrapper, 'touchstart').pipe(
        takeUntil(this.destroyed$)
      ).subscribe((e:TouchEvent) => {
        this.serviceTStart = e.touches[0].clientX;;
      })
      fromEvent(serviceWrapper,'mousedown').pipe(
        takeUntil(this.destroyed$)
      ).subscribe((e:MouseEvent) => {
        this.serviceMStartX = e.clientX;
        this.serviceMStartY = e.clientY;
        this.animationGoing = true
      })
      fromEvent(serviceWrapper,'mousemove').pipe(
        takeUntil(this.destroyed$)
      ).subscribe((e:MouseEvent) => {
        
        if(!this.serviceMStartX && !this.serviceMStartY){
          return
        }
        let endX = e.clientX;
        let endY = e.clientY;

        let deltaX = endX - this.serviceMStartX;

        if (Math.abs(deltaX) >= 10) {
          if (deltaX > 0) {
            this.serviceTogglFunc(-1)
            this.serviceMStartX = null
            this.serviceMStartY = null;
            this.animationGoing = false
          } else {
            this.serviceTogglFunc(1)
            this.serviceMStartX = null
            this.serviceMStartY = null;
            this.animationGoing = false
          }
        }
        this.animationGoing = false
      })
      fromEvent(serviceWrapper,'mouseup').pipe(
        takeUntil(this.destroyed$)
      ).subscribe((e:MouseEvent) => {
        this.serviceMStartX = null
        this.serviceMStartY = null;
      })
      
      fromEvent(serviceWrapper, 'touchend').pipe(
        takeUntil(this.destroyed$)
      ).subscribe((e:TouchEvent) => {
        let serviceTEnd = e.changedTouches[0].clientX
        let distance = serviceTEnd - this.serviceTStart
        if (distance > 30) {
          // User swiped right
          this.serviceTogglFunc(-1)
        }
        else if (distance < -30) {
          // User swiped up
          this.serviceTogglFunc(1)
        }
      })
    }
      

    if(this.type === 'mobile'){
      
      if(!(this.swiper instanceof Swiper)){
        this.swiper = new Swiper('.swiper-stats',{
          modules: [Navigation],
          direction: 'horizontal',
          slidesPerView: 3,
          speed: 800,
          effect: 'slide',
          loop: true,
          longSwipes: false,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          }
        })
      }
    }else{
      this.swiper = 'none'
    }
    this.selectedSection = 'header'
    this.loading = false 
    this.cdr.detectChanges()
    // Logic for Swiper adding if resize breakpoints changing from tablet to mobile 
    
    // Old routing between section using params

    // if(
    //   this.activateRoute.snapshot.queryParams['view'] &&
    //   ['stats','services','reviews','careers'].includes(this.activateRoute.snapshot.queryParams['view'])
    // ){
    //   this.selectedSection = this.activateRoute.snapshot.queryParams['view']
    //   this.sectionIndex = this.sectionArray.indexOf(this.activateRoute.snapshot.queryParams['view'])
      
    // }else{
    // }
  }
  subfaqsAnimGoing:boolean = false
  faqsAnim$:Subject<void> = new Subject<void>()
  animH:string
  faqSectionIndex:number = 1;
  maxFaqSecInd:number;
  faqSettings:object;
  minFaqCardH:string
  minFaqPopH:string

  serviceOptions:{
    positions: any,
    lastServiceSection: number,
    order: any,
    titlePositions?: any
  };
  lastServiceSection;
  // Adaptive resize observer function
  private resizeBreakpointsHandler():void {
    if(this.breakpointObserver.isMatched('(min-width: 1920px)')){
      this.type = 'desktop';
      // this.fastViewHide = false
      this.arrowRotation = 'horizontal'
      setTimeout(() => {
        this.quoteForm ? this.quoteForm.nativeElement.style = '' : undefined;
      },3000)
      this.faqSettings = {
        1: '1,ten,elev,twel,2,3,4,5,6',
        2: '7,8,9',
      }
      this.maxFaqSecInd = 2
      this.faqSectionIndex = this.faqSectionIndex > this.maxFaqSecInd ? this.maxFaqSecInd : this.faqSectionIndex
      // depending .list-conteiner decreasing
      this.minFaqCardH = 'clamp(111px,13.2vh,130px)'
      // depending .popular-text decreasing
      this.minFaqPopH = 'clamp(147px,18.3vh,180px)'
      this.serviceOptions = {
        positions: {
          first: 'translateX(0%)',
          second: 'translateX(-31%)',
          third: 'translateX(-31%)',
          last: 'translateX(-31%)',

          final: 'translateX(-31%)',
          firstShake: 'translateX(3%)',
          lastShake: 'translateX(-34%)'
        },
        order: {
          1: 'first',
          2: 'second',
          3: 'third',
          4: 'last',
          9: 'final'
        },
        lastServiceSection: 3,
      }
      setTimeout(() => {
        this.serviceSection?.nativeElement ? this.serviceSection.nativeElement.style.transform = this.serviceOptions.positions[this.serviceOptions.order[this.serviceIndex]] : undefined
      },100)
      this.cdr.detectChanges()
    }
    else if(this.breakpointObserver.isMatched('(min-width: 1440px)')){
      this.type = 'macxl';
      this.arrowRotation = 'horizontal'
      setTimeout(() => {
        this.quoteForm ? this.quoteForm.nativeElement.style = '' : undefined;
      },3000)
      this.faqSettings = {
        1: '1,ten,elev,twel,2,3,4,5,6',
        2: '7,8,9',
      }
      this.maxFaqSecInd = 2
      this.faqSectionIndex = this.faqSectionIndex > this.maxFaqSecInd ? this.maxFaqSecInd : this.faqSectionIndex
      this.minFaqCardH = 'min(97px,11.8vh)'
      this.minFaqPopH = 'min(138px,16.8vh)'
      this.serviceOptions = {
        positions: {
          first: 'translateX(0%)',
          second: 'translateX(-30%)',
          third: 'translateX(-65%)',
          last: 'translateX(-65%)',
          final: 'translateX(-65%)',
          firstShake: 'translateX(3%)',
          lastShake: 'translateX(-68%)'
        },
        order: {
          1: 'first',
          2: 'second',
          3: 'third',
          4: 'last',
          9: 'final'
        },
        lastServiceSection: 4,
      }
      setTimeout(() => {
        this.serviceSection?.nativeElement ? this.serviceSection.nativeElement.style.transform = this.serviceOptions.positions[this.serviceOptions.order[this.serviceIndex]] : undefined
      },100)
      this.cdr.detectChanges()
    }
    else if(this.breakpointObserver.isMatched('(min-width: 1280px) and (min-height: 832px), (min-width: 1400px)')){
      this.type = 'mac';
      this.arrowRotation = 'horizontal'
      setTimeout(() => {
        this.quoteForm ? this.quoteForm.nativeElement.style = '' : undefined;
      },3000)
      this.faqSettings = {
        1: '1,ten,elev,twel,2,3,4,5,6',
        2: '7,8,9',
      }
      this.maxFaqSecInd = 2
      this.faqSectionIndex = this.faqSectionIndex > this.maxFaqSecInd ? this.maxFaqSecInd : this.faqSectionIndex
      this.minFaqCardH = 'min(97px,12.9vh)'
      this.minFaqPopH = 'min(138px,18.4vh)'
      this.serviceOptions = {
        positions: {
          first: 'translateX(0%)',
          second: 'translateX(-28%)',
          third: 'translateX(-62%)',
          last: 'translateX(-62%)',
          final: 'translateX(-62%)',
          firstShake: 'translateX(3%)',
          lastShake: 'translateX(-65%)'
        },
        order: {
          1: 'first',
          2: 'second',
          3: 'third',
          4: 'last',
          9: 'final'
        },
        lastServiceSection: 4,
      }
      setTimeout(() => {
        this.serviceSection?.nativeElement ? this.serviceSection.nativeElement.style.transform = this.serviceOptions.positions[this.serviceOptions.order[this.serviceIndex]] : undefined
      },100)
      this.cdr.detectChanges()
    }
    else if(this.breakpointObserver.isMatched('(min-width: 1280px)')){
      this.type = 'ipadxl';
      this.arrowRotation = 'horizontal'
      this.faqSettings = {
        1: '1,ten,elev,twel,2,3,4,5,6',
        2: '7,8,9',
      }
      this.maxFaqSecInd = 2
      this.faqSectionIndex = this.faqSectionIndex > this.maxFaqSecInd ? this.maxFaqSecInd : this.faqSectionIndex
      this.minFaqCardH = 'min(97px,12.9vh)'
      this.minFaqPopH = 'min(138px,18.4vh)'
      this.serviceOptions = {
        positions: {
          first: 'translateX(0%)',
          second: 'translateX(-28%)',
          third: 'translateX(-62%)',
          last: 'translateX(-62%)',
          final: 'translateX(-62%)',
          firstShake: 'translateX(3%)',
          lastShake: 'translateX(-65%)'
        },
        order: {
          1: 'first',
          2: 'second',
          3: 'third',
          4: 'last',
          9: 'final'
        },
        lastServiceSection: 4,
      }
      setTimeout(() => {
        this.serviceSection?.nativeElement ? this.serviceSection.nativeElement.style.transform = this.serviceOptions.positions[this.serviceOptions.order[this.serviceIndex]] : undefined
      },100)
      this.cdr.detectChanges()
    }
    else if(this.breakpointObserver.isMatched('(min-width: 1024px)')) {
      this.type = 'tabletxl';
      this.arrowRotation = 'horizontal'
      this.faqSettings = {
        1: '1,ten,elev,twel,2,3,4,5,6',
        2: '7,8,9',
      }
      this.maxFaqSecInd = 2
      this.faqSectionIndex = this.faqSectionIndex > this.maxFaqSecInd ? this.maxFaqSecInd : this.faqSectionIndex
      this.minFaqCardH = 'min(78px,12vh)'
      this.minFaqPopH = 'min(115px,17.69vh)'
      this.serviceOptions = {
        positions: {
          first: 'translateX(0%)',
          second: 'translateX(-25%)',
          third: 'translateX(-63%)',
          last: 'translateX(-104%)',
          final: 'translateX(-104%)',
          firstShake: 'translateX(3%)',
          lastShake: 'translateX(-107%)'
        },
        order: {
          1: 'first',
          2: 'second',
          3: 'third',
          4: 'last',
          9: 'final'
        },
        lastServiceSection: 5,
      }
      // Set timeout because onInit ViewChild elements is not initializated in OnInit life cycle
      setTimeout(() => {
        this.serviceSection?.nativeElement ? this.serviceSection.nativeElement.style.transform = this.serviceOptions.positions[this.serviceOptions.order[this.serviceIndex]] : undefined
      },100)
      
      this.cdr.detectChanges()
    }
    else if(this.breakpointObserver.isMatched('(min-width: 800px)')){
      this.type = 'ipad';
      this.arrowRotation = 'vertical'
      this.faqSettings = {
        1: 'ten,elev,twel,1,2,3,4,5',
        2: '9,6,7,8',
      }
      this.maxFaqSecInd = 2
      this.faqSectionIndex = this.faqSectionIndex > this.maxFaqSecInd ? this.maxFaqSecInd : this.faqSectionIndex
      this.minFaqCardH = 'min(107px,11.38vh)'
      this.minFaqPopH = 'min(140px,14.89vh)'
      this.serviceOptions = {
        positions: {
          first: 'translateX(0%)',
          second: 'translateX(-68%)',
          third: 'translateX(-120%)',
          last: 'translateX(-175%)',
          final: 'translateX(-175%)',
          firstShake: 'translateX(3%)',
          lastShake: 'translateX(-178%)'
        },
        order: {
          1: 'first',
          2: 'second',
          3: 'third',
          4: 'last',
          9: 'final'
        },
        lastServiceSection: 5
      }
      setTimeout(() => {
        this.serviceSection?.nativeElement ? this.serviceSection.nativeElement.style.transform = this.serviceOptions.positions[this.serviceOptions.order[this.serviceIndex]] : undefined
      },100)
      this.cdr.detectChanges()
    }
    else if(this.breakpointObserver.isMatched('(min-width: 768px)')){
      this.type = 'tablet';
      this.arrowRotation = 'vertical'
      this.faqSettings = {
        1: 'ten,elev,twel,1,2,3,4,5',
        2: '9,6,7,8',
      }
      this.maxFaqSecInd = 2
      this.faqSectionIndex = this.faqSectionIndex > this.maxFaqSecInd ? this.maxFaqSecInd : this.faqSectionIndex
      this.minFaqCardH = 'min(100px,11.36vh)'
      this.minFaqPopH = 'min(136px,15.45vh)'
      this.serviceOptions = {
        positions: {
          first: 'translateX(0%)',
          second: 'translateX(-68%)',
          third: 'translateX(-120%)',
          last: 'translateX(-175%)',
          final: 'translateX(-175%)',
          firstShake: 'translateX(3%)',
          lastShake: 'translateX(-178%)'
        },
        order: {
          1: 'first',
          2: 'second',
          3: 'third',
          4: 'last',
          9: 'final'
        },
        lastServiceSection: 5
      }
      setTimeout(() => {
        this.serviceSection?.nativeElement ? this.serviceSection.nativeElement.style.transform = this.serviceOptions.positions[this.serviceOptions.order[this.serviceIndex]] : undefined
      },100)
      this.cdr.detectChanges()
    }
    else{
      this.type = 'mobile'
      this.arrowRotation = 'vertical'
      this.faqSettings = {
        1: '1,2,3',
        2: 'ten,elev,twel',
        3: '7,8,9',
        4: '4,5,6'
      }
      this.maxFaqSecInd = 4
      this.minFaqCardH = 'min(11.9vw,7vh)' 
      this.minFaqPopH = 'min(18.8vw,11.08vh)'
      if(!(this.swiper instanceof Swiper) && this.swiper !== undefined){
        setTimeout(() => {
          this.swiper = new Swiper('.swiper-stats',{
            modules: [Navigation],
            direction: 'horizontal',
            slidesPerView: 3,
            speed: 800,
            effect: 'slide',
            loop: true,
            longSwipes: false,
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev'
            }
          })
        },100)
      }
      this.cdr.detectChanges()
    }
  }
  // Loader for video waiting(loading)
  videoLoading: boolean = false
  firstLoad: boolean = true
  videoWaiting():void{
    if(this.firstLoad){
      this.videoLoading = true
      this.firstLoad = false
    }
    
    // alert('waiting')
  }
  videoPlaying():void{
    this.videoLoading = false
    // alert('playing')
  }
  // Menu toggl logic
  menuToggl():void{
    if(this.animationGoing){
      return
    }
    this.menuShown = !this.menuShown
    this.menuShown ? this.selectedSection = 'menu' : this.selectedSection = 'header'
  }
  // Navigating to section using panel in menu
  goToSection(event):void{
    if(event.target.id === '' || event.target.id ==='getQuote' || this.animationGoing){
      return
    }
    this.animationGoing = true
    this.selectedSections.splice(this.sectionArray.indexOf(event.target.id),1)
    this.selectedSection = event.target.id
    this.sectionIndex = this.sectionArray.indexOf(event.target.id)
    this.menuShown = false
  }
  sectionDelayed:boolean = false
  // Navigating through sections functions
  showNextSection():void{
    if(this.sectionArray[this.sectionIndex + 1] && !this.animationGoing && !this.sectionDelayed){
      this.sectionDelayed = true
      this.animationGoing = true
      this.selectedSection = this.sectionArray[this.sectionIndex + 1]
      this.sectionIndex += 1
      setTimeout(() => {
        this.sectionDelayed = false
      },750)
    }
    // if(this.activateRoute.snapshot.queryParams['view']){
    //   this.router.navigate([''])
    // }
  }
  showPreviousSection():void{
    if(this.sectionArray[this.sectionIndex - 1] && !this.animationGoing && !this.sectionDelayed){
      this.sectionDelayed = true
      this.animationGoing = true
      this.selectedSection = this.sectionArray[this.sectionIndex - 1]
      this.sectionIndex -= 1
      setTimeout(() => {
        this.sectionDelayed = false
      },750)
      
    }
    
    // if(this.activateRoute.snapshot.queryParams['view']){
    //   this.router.navigate([''])
    // }
  }
  // Going to header
  backToTop():void{
    this.selectedSection = 'header'
    this.sectionIndex = 0
    this.refreshAnimations()
  }
  // Refreshing all animations to show them again
  refreshAnimations():void{
    this.selectedSections = []
    this.openedFaq = ''
    this.map.nativeElement.currentTime = 3
    this.map.nativeElement.pause()
  }
  // Navigate to quote-form page
  goToQuote():void{
    this.router.navigate(['quote-form'], { queryParams: this.params })
  }
  // Animation finished handler
  animationFinished(event:any):void{
    if(event.fromState != 'void' && event.element.id === this.selectedSection){
      if(event.toState === 'map'){
        if(!(this.selectedSections.includes('map'))){
          this.map.nativeElement.currentTime = 3
          this.map.nativeElement.muted = true
          this.map.nativeElement.play().then(() => {
            this.touchedOnce = true
          })
        }
      }
      if(event.fromState === 'map'){
        this.touchedDownOnce = false
        this.showArrow = false
      }
      if(event.toState === 'faqs' && ['map','menu'].includes(event.fromState) && !(this.selectedSections.includes('faqs'))){
        if(this.type === 'mobile'){
          this.openedFaq = this.faqSettings[this.faqSectionIndex].split(',')[1]
        }else{
          this.openedFaq = this.faqSettings[this.faqSectionIndex].split(',')[0]
        }
        this.subfaqsAnimGoing = true
        setTimeout(() => {
          this.subfaqsAnimGoing = false
        },1000)
      }
      if(event.totalTime === 0 && !this.disableAnimations){
          this.animationGoing = false
      }
      if(!this.selectedSections.includes(event.toState)){
        this.selectedSections.push(event.toState)
      }
      
    this.animationGoing = false
    }
    // refresh only when came from stats
    if(event.fromState !== 'void' && event.fromState !== 'none' && event.toState === 'header' && event.triggerName === 'statsElements'){
      this.refreshAnimations()
    }
  }
  togglReviewsAnimation(event,el?):void{
    let element:any = document.querySelector(`[data-place="${el}"]`)
    // console.log('typeof ELEMENT',!!element.style);
    
    if(!!element.style && element.style.animationPlayState === 'paused'){
      element.style.animationPlayState = 'running'
    }else if(!!element.style){
      element.style.animationPlayState = 'paused'
    }
  }
  togglFaqs(id){
    if(this.subfaqsAnimGoing || this.animationGoing){
      return
    }
    if(this.openedFaq === ''){
      this.subfaqsAnimGoing = true
      setTimeout(() => {
        this.subfaqsAnimGoing = false
      },500)
      this.openedFaq = id
      // console.log('No FAQ was choosed before');
      this.faqsAnim$.pipe(
        take(1)
      ).subscribe({
        next: () => {
          this.animH = null
          this.cdr.detectChanges()
        }
      })
      return
    }
    if(this.openedFaq === id){
      this.subfaqsAnimGoing = true
      setTimeout(() => {
        this.subfaqsAnimGoing = false
      },500)
      let currentPlayedH:any = document.querySelector(`#card-${id} [data-back]`)
      if(currentPlayedH){
        this.animH = `${currentPlayedH.offsetHeight}px`
      }
      this.openedFaq = ''
      this.cdr.detectChanges()
      // console.log('The same FAQ was choosed');
      this.faqsAnim$.pipe(
        take(1)
      ).subscribe({
        next: () => {
          this.animH = null
          this.cdr.detectChanges()
        }
      })
      return
    }
    let currentPlayedH:any = document.querySelector(`#card-${this.openedFaq} [data-back]`)
    if(currentPlayedH){
      this.animH = `${currentPlayedH.offsetHeight}px`
    }
    this.openedFaq = ''
    this.cdr.detectChanges()
    // We need to wait untill other animation is finished
    this.faqsAnim$.pipe(
      take(1)
    ).subscribe({
      next: () => {
        this.openedFaq = id
        this.animH = null
      }
    })
  }
  faqsAnimFinish(e){
    this.faqsAnim$.next()
  }
  showFaqCard(id):boolean{
    // mobile - by 1,2,3 -> 4,5,6 -> 7,8,9 -> 10,11,12
    // tablet,ipad - by 1,2,3,4,5,6,7,8 -> 9,10,11,12
    // tabletxl,ipadxl,mac,macxl,desktop - by 1,2,3,4,5,6,7,8,9 -> 10,11,12
    if(this.faqSettings[this.faqSectionIndex].includes(id)){
      return true
    }
    return false
  }
  morelessFaqs(option:number){
    if(this.subfaqsAnimGoing || this.animationGoing){
      return
    }
    this.faqSectionIndex += option
    setTimeout(() => {
      this.openedFaq = ''
      this.animH = null
    },700)
  }
  serviceIndex:number = 1;
  serviceAnim$:Subject<void> = new Subject<void>()
  serviceAnimGoing:boolean = false
  serviceTogglFunc(option:number){
    if(this.serviceAnimGoing){
      return
    }
    if(this.type === 'mobile'){
      if(this.serviceIndex + option > 5){
        this.serviceIndex = 6
        this.serviceAnim$.pipe(
          take(1)
        ).subscribe({
          next: () => {
              this.serviceIndex = 1
              this.cdr.detectChanges()
            // }
          }
        })
        return
      }
      if(this.serviceIndex + option < 1){
        this.serviceIndex = 0
        // this.serviceAnimGoing = true
        // console.log('before',this.serviceAnimGoing);
        this.serviceAnim$.pipe(
          take(1)
        ).subscribe({
          next: () => {
            // console.log('sIndInfo',sInd,this.sectionIndex);
            // if(sInd === this.sectionIndex){
              this.serviceIndex = 5
              // this.serviceAnimGoing = false
              this.cdr.detectChanges()
            // }
          }
        })
        return
      }
      this.serviceIndex += option
      this.serviceAnim$.pipe(
        take(1)
      ).subscribe({
        next: () => {
          this.serviceAnimGoing = false
        }
      })
    }
    if(this.type !== 'mobile'){
      this.serviceAnimGoing = true
      if(this.serviceIndex + option === this.serviceOptions.lastServiceSection){
        this.serviceIndex = 9
      }else{
        this.serviceIndex += option
      }
      this.serviceAnim$.pipe(
        take(1)
      ).subscribe({
        next: () => {
          this.serviceIndex === 9 ? this.serviceIndex = this.serviceOptions.lastServiceSection - 1 : undefined
          this.serviceSection?.nativeElement ? this.serviceSection.nativeElement.style.transform = this.serviceOptions.positions[this.serviceOptions.order[this.serviceIndex]] : undefined
          if(this.serviceIndex === 0){
            this.serviceIndex = 1
            this.cdr.detectChanges()
          }
          this.serviceAnimGoing = false
        }
      })
    }
  }
  serviceAnimFinish(e){
    if(
      [0,1,2,3,4,5,6,9].includes(e.fromState) &&
      [0,1,2,3,4,5,6,9].includes(e.toState)
    ){
      this.serviceAnim$.next()
    }
    if(
      (e.fromState === 0 || e.fromState === 6) &&
      (this.type === 'mobile')
    ){
        this.serviceAnimGoing = false
    }
  }
  serviceAnimStart(e){
    if(e.toState === 0 || e.toState === 6){
      this.serviceAnimGoing = true
    }
  }
  
  // Question input data send logic
  async postMessage():Promise<void>{
    if(this.messageContent.length < 2){
      return
    }
    this.loading = true
    this.service.postMessage(this.messageContent).pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(
      {
        next: (res) => {
          if(res.data.success){
            console.log("%cYour message was succesfully sent!","color:#9ed870")
            this.messageContent = ''
            this.questionInput.nativeElement.placeholder = 'Your message was succesfully sent!'
            this.questionInput.nativeElement.classList.add('success')
          }
          this.loading = false
        },
        error:(err) => {
          console.error(err)
          alert(`We are apologize but it seems some error occurred on our side.\nWe will fix it asap \n"${err.error.message}"`)
          this.loading = false
        }
      }
    )
  }
  touchStartX
  // Detecting swipe direction
  @HostListener('document:touchstart', ['$event','passive'])
  onTouchStart(event: TouchEvent):void {
      this.touchStartY = event.touches[0].clientY;
      this.touchStartX = event.touches[0].clientX;
  }
  
  @HostListener('document:touchend', ['$event','passive'])
  onTouchEnd(event: TouchEvent):void {
    if(event.target instanceof Element ?
        event.target.classList.contains('map-video') : undefined){
      return
    }
    if(this.animationGoing || this.menuShown){
      return
    }
    const touchEndY = event.changedTouches[0].clientY;
    const touchEndX = event.changedTouches[0].clientX
    
    // User can toggl section only when his swipe by y axis more than by x axis
    let canToggl = Math.abs(touchEndX - this.touchStartX) < Math.abs(touchEndY - this.touchStartY)

    if (this.touchStartY-90 > touchEndY && canToggl) {
      // User swiped down
      this.showNextSection();
    } else if (this.touchStartY+90 < touchEndY && canToggl) {
      // User swiped up
      this.showPreviousSection();
    }
  }
  // Detection mousewheel direction ( Date.now() check is added because of inertial wheel on IOS/Mac)
  lastDate:number = Date.now()
  @HostListener('document:wheel', ['$event','passive'])
  onMouseWheel(event: WheelEvent):void {
    
    if(this.animationGoing || this.menuShown || Math.abs((Date.now() - this.lastDate)) <= 50){
      this.lastDate = Date.now()
      return
    }
    this.lastDate = Date.now()
    if (event.deltaY < 0) {
      // User is scrolling up
      
      this.showPreviousSection()
    } else if (event.deltaY > 0) {
      // User is scrolling down
      this.showNextSection()
    }
  }
  ngOnDestroy():void{
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
