<div class="content-wrapper">
    @if (loading) {
        <div [@fadeOutIn] class="loading">
            <img draggable="false" class="logo"  src="../assets/Other/Logo-white.svg"  alt="loading">
            <img draggable="false" loading='lazy' class="circle" src="../assets/Other/Loading-circle.webp"  alt="loading">
        </div>
    }

    <!-- Menu -->
    @if (menuShown) {
        <section [@menu]  class="menu">
            <main class="main">
                <div class="top">
                    <div class="goBack" (click)="menuToggl()">
                        <img draggable="false" class="img" src="../assets/Other/chevron-white.svg" alt="back">
                        <div class="text">Back</div>
                    </div>
                    <span class="top__el">Menu</span>
                </div>
                <div class="list">
                    <div class="list__el">
                        <span (click)="goToQuote()"  id="getQuote" class="text">Get A Quote</span>
                    </div>
                    <p  target="blank" class="list__el">
                        <a tabindex="-1" href="https://app.haulex.com/#/login" title="HaulEx site login" class="text">Login</a>
                    </p>
                </div>
                <ul  class="sub-list" (click)="goToSection($event)">
                    <li class="sub-list__el" id="stats">Our Focus</li>
                    <li class="sub-list__el" id="map">Service Locations</li>
                    <li class="sub-list__el" id="service">Service Options</li>
                    <li class="sub-list__el" id="faqs">FAQ`S</li>
                    <li class="sub-list__el" id="careers">Careers</li>
                    <li class="sub-list__el" id="reviews">Reviews</li>
                </ul>
            </main>
            <a tabindex="-1" href="tel:208-922-9297" title="HaulEx app login" class="number">208-922-9297</a>
    
        </section>
    }
    
    <!-- Header -->
    <div class="section-wrapper" [@header]="selectedSection" >
        <header  [@headerElements]="selectedSection" (@headerElements.done)="animationFinished($event)" id="header" class="header">
            
            <main class="main">
                <div class="content-wrapperr">
                    <nav  class="nav">
                        <img draggable="false" loading="lazy" class="nav__logo" src="../assets/Other/C2C-white.svg" alt="C2C AUTO TRANSPORT">
                        <div class="nav__sidebar">
                            @if (['desktop','macxl','mac','ipadxl','tabletxl'].includes(type)) {
                                <a tabindex="-1" class="number" title="help phone number" href="tel:208-922-9297">208-922-9297</a>
                            }
                            @if (type !== 'mobile') {
                                <img draggable="false" class="img" src="../assets/Other/Logo-white.svg" alt="HaulEx logo">
                                <a tabindex="-1" class="text" href="https://app.haulex.com/#/login" title="HaulEx app login">Login</a>
                                <div class="white-line"></div>
                            }
                            <img draggable="false" [ngStyle]="{ marginRight: type !== 'mobile' ? '0px' : '10px' }" (click)="menuToggl()" class="burger" src="../assets/Other/burger-white.svg" alt="Menu">
                        </div>
                    </nav>
                    <div class="main-text">
                        <div>
                            <div class="title">
                                <!-- Each word divided to separated p to provide step animation to each word with different interval appearing -->
                                @if(['mobile','tablet','ipad','tabletxl','ipadxl'].includes(type)){
                                    <div class="cont">
                                        <p>Y</p><p>o</p><p>u</p><p>r&nbsp;</p>
                                        <p>A</p><p>u</p><p>t</p><p>o</p>
                                    </div>
                                }@else{
                                    <div class="cont">
                                        <p>Y</p><p>o</p><p>u</p><p>r&nbsp;</p>
                                    </div>
                                    <div class="cont">
                                        <p>A</p><p>u</p><p>t</p><p>o</p>
                                    </div>
                                }
                                <div class="cont">
                                    <p>T</p><p>r</p><p>a</p><p>n</p><p>s</p><p>p</p><p>o</p><p>r</p><p>t</p>
                                </div>
                                <div class="cont">
                                    <p>E</p><p>x</p><p>p</p><p>e</p><p>r</p><p>t</p><p>s!</p>
                                </div>
                            </div>
                            <div class="sub-text">
                                Safe & Reliable Service In All 50 States!
                             </div>
                        </div>

                        
                        <!-- @if (['desktop','macxl','mac'].includes(type) && !linkLoaded) {
                            <img draggable="false" #img src="../assets/Other/quote-form-thumbnail.webp" class="getAQuote thumbnail absolute right-0">
                        } -->
                        @if (['desktop','macxl','mac'].includes(type) && linkLoaded) {
                            <iframe class="getAQuote haulex-form" 
                            title="Get A Quote"
                            #iframeForm
                            style="border-width: 0;" 
                            data-referenceid="9ce7ce8df2" 
                            data-source="C2C" 
                            data-onsuccess="thankyou" 
                            [src]="link"
                            [attr.data-url]="dataUrl"
                            data-gtm-yt-inspected-7="true">
                            </iframe>
                        }
                        
                    </div>
                    <div class="bottom">
                        <button tabindex="-1" (click)="goToQuote()" class="bottom__button quote">Get a quote</button>
                        <button tabindex="-1" class="bottom__button track">Track my vehicle</button>
                    </div>
                </div>
            </main>
    
            <div class="gradient"></div> 
    
        </header>
    </div>
    
    <!-- Stats -->
    <div class="section-wrapper" [@stats]="selectedSection">
        <section [attr.data-disabled]="selectedSections.includes('stats') ? true : false" [@statsElements]="{value: selectedSection, params: {
            rotation: arrowRotation === 'horizontal' ? 'translateX(-30%)' : 'translateY(-30%)'
        }}" (@statsElements.done)="animationFinished($event)" id="stats" class="stats">
            <main class="main">
                @if (['mobile','tablet','ipad'].includes(type)) {
                    <div (click)="goToQuote()"  id="getQuote"  class="getAQuote-wrapper getA">
                        <img draggable="false" loading='lazy' class="circle" src="../assets/Other/GetAQuote-circle.svg" alt="GetAQuote">
                        <img draggable="false" loading='lazy' class="arrow" src="../assets/Other/short-arrow.svg" alt="arrow">
                    </div>
                }
                
                <div class="title-wrapper">
                    <h2 class="text">
                        <p>Since 1999,</p>
                        <p>our Focus</p> 
                        <p>is still the same.</p>  
                    </h2>
                    <h2 class="text">
                        <p>Simply provide the</p>
                        <p>best auto transport</p>
                        <p>experience to our</p>
                        <p>customer!</p>
                    </h2>
                </div>
                <div class="image-down">
                    <!-- <div class="arrow-conteiner"> -->
                        <picture class="img">
                            <source srcset="
                            ../assets/Other/slide-down2.svg,
                            " media="(min-width: 1280px)" />
                            <source srcset="
                            ../assets/Other/slide-down3.svg,
                            " media="(min-width: 1920px)" 
                            />
                            <img draggable="false" class="img_" src="../assets/Other/slide-down.svg" alt="">
                        </picture>
                    <!-- </div> -->
                </div>
                @if (type === 'mobile' || type === '') {
                    <div class="swiper-stats swiper">
                        <div class="stats-tape swiper-wrapper">
                            <div class="stat swiper-slide">
                                <h3 class="title">2475+</h3>
                                <p class="sub-text">Cars Moved <br> Each Month</p>
                            </div>
                            <div class="stat swiper-slide">
                                <h3 class="title">150+</h3>
                                <p class="sub-text">Hot Routes <br> Serviced Weekly</p>
                            </div>
                            <div class="stat swiper-slide">
                                <h3 class="title">55+</h3>
                                <p class="sub-text">Trucks Under <br> Our Family</p>
                            </div>
                            <div class="stat swiper-slide">
                                <h3 class="title">1269+</h3>
                                <p class="sub-text">Carriers In <br> Our Network</p>
                            </div>
                            <div class="stat swiper-slide">
                                <h3 class="title">100%</h3>
                                <p class="sub-text">Compliant <br> &  Insured</p>
                            </div>
                        </div>
                    <div class="arrow-toggls">
                        <img draggable="false" class="img swiper-button-prev" src="../assets/Other/chevron-white.svg" alt="left">
                        <img draggable="false" class="img swiper-button-next" src="../assets/Other/chevron-white.svg" alt="right">
                    </div>
                </div>
                }
                @if (type !== 'mobile' && type !== '') {
                    <div class="stats">
                        @if (!['mobile','tablet','ipad'].includes(type)) {
                            <div (click)="goToQuote()"  id="getQuote" class="getAQuote-wrapper getA">
                                <img draggable="false" loading='lazy' class="circle" src="../assets/Other/GetAQuote-circle.svg" alt="GetAQuote">
                                <img draggable="false" loading='lazy' class="arrow" src="../assets/Other/short-arrow.svg" alt="arrow">
                            </div>
                        }
                        
                        <div class="stat first">
                            <h3 class="title">2475+</h3>
                            <p class="sub-text">Cars Moved Each Month</p>
                        </div>
                        <div class="stat second">
                            <h3 class="title">150+</h3>
                            <p class="sub-text">Hot Routes Serviced Weekly</p>
                        </div>
                        <div class="stat third">
                            <h3 class="title">55+</h3>
                            <p class="sub-text">Trucks Under Our Family</p>
                        </div>
                        <div class="stat fourth">
                            <h3 class="title">1269+</h3>
                            <p class="sub-text">Carriers In Our Network</p>
                        </div>
                        <div class="stat fifth">
                            <h3 class="title">100%</h3>
                            <p class="sub-text">Compliant & Insured</p>
                        </div>
                    </div>
                }
                
            </main>
        </section>
    </div>
    
    <!-- Map -->
    <div class="section-wrapper" [@map]="selectedSection">
    <section [attr.data-disabled]="selectedSections.includes('map') ? true : false" [@mapElements]="selectedSection" (@mapElements.done)="animationFinished($event)" id="map" class="map">
        <main class="main">
            <h2 class="title">Real Time Pickups & Deliveries 2023</h2>
            <div class="map-view-conteiner">
                <div class="gradient-overlay1"></div>
                
                <div id="map-wrapper" class="map-wrapper" [ngStyle]="{ overflowX: videoLoading ? 'hidden' : 'auto' }">
                    
                    <div #videoConteiner id="video-conteiner" class="video-conteiner">
                        <div class="gradient-overlay3"></div>
                        @if (!supportsVideo) {
                        <img draggable="false" #map id="video" src="../assets/Map/Map-black.mp4" class="map-video"  alt="">
                        }

                        <!--  -->
                        @if (supportsVideo) {
                            <video id="video" (waiting)="videoWaiting()" (playing)="videoPlaying()" poster="../assets/Map/Map-thumbnail.webp" class="map-video" preload="yes" [controls]="!touchedOnce" #map [defaultMuted]="true" muted playsinline [playsInline]="true">
                                <source class="video_" src="../assets/Map/Map.mov" type="video/mp4">
                                <source class="video_" src="../assets/Map/Map.mp4" type="video/mp4">
                                <source class="video_" src="../assets/Map/Map.webm" type="video/webm">
                                <source class="video_" src="../assets/Map/Map.mov" type="video/mov">
                                <p class="text-[30px] text-red font-poppins z-[50] absolute top-[50%] left-[50%]">Your browser doesn`t support video tag</p>
                            </video>
                        }
                        @if (videoLoading) {
                            <div [@fadeOutIn] class="loading video">
                                <img draggable="false" class="logo"  src="../assets/Other/Logo-white.svg"  alt="loading">
                                <img draggable="false" loading='lazy' class="circle" src="../assets/Other/Loading-circle.webp"  alt="loading">
                            </div>
                        }
                        
                        <div class="gradient-overlay4"></div>
                    </div>
                </div>
                <div class="gradient-overlay2"></div>
                
            </div>
            @if ((!(['desktop'].includes(type))) && showArrow) {
                <div [@mapArrow] (click)="showNextSection()" class="arrow-down">
                    <img draggable="false" src="../assets/Map/arrow-down.svg" alt="NEXT">
                </div>
            }
        </main>
    </section>
    </div>

    <!-- Service -->
    <div class="section-wrapper" [@service]="selectedSection">
        <section [attr.data-disabled]="selectedSections.includes('service') ? true : false" [@serviceElements]='selectedSection' (@serviceElements.done)="animationFinished($event)" id="service" class="service">
            <main class="main">
                <div class="tape-conteiner" id="tape-conteiner">
                        <div class="title-conteiner">
                        @if(type === 'mobile'){
                            @if(
                                [3,4,5,0].includes(serviceIndex)
                            ){
                                <h3 [@serviceFadeOutIn] class="title">Service Options</h3>
                            }
                            @if(
                                [1,2,6].includes(serviceIndex)
                            ){
                                <h3 [@serviceFadeOutIn] class="title">Transport Types</h3>
                            }
                        }
                        @if(['tablet','ipad'].includes(type)){
                            @if(
                                [2,3,4,5,9].includes(serviceIndex)
                            ){
                                <h3 [@serviceFadeOutIn] class="title">Service Options</h3>
                            }
                            @if(
                                [0,1].includes(serviceIndex)
                            ){
                                <h3 [@serviceFadeOutIn] class="title">Transport Types</h3>
                            }
                        }

                        </div>
                        @if(type ==='mobile'){
                            <div [@mobileServiceToggl]="{
                                value:serviceIndex
                            }" (@mobileServiceToggl.start)="serviceAnimStart($event)" (@mobileServiceToggl.done)="serviceAnimFinish($event)" class="types-wrapper">
                                <div id="types-conteiner" class="types-conteiner">
                                    <!-- <div class="card-tape-wrapper"> -->
                                        <div class="card-wrapper">
                                            <div class="card-title-wrapper">
                                                <div class="card">
                                                    <main class="card-body">
                                                        <div class="body-wrapper">
                                                            <img draggable="false" class="img" src="../assets/service/expidited-service.svg" alt="Open">
                                                            <h4 class="heading">Expidited</h4>
                                                            <div class="line"></div>
                                                            <p class="text">
                                                                You can choose expedited service option when you have urgent, time sensitive schedules. Your shipment is prioritized to the front of the line with team drivers upon availability. This allows your vehicle to be delivered faster then industry standard without jeopardizing the safety of your vehicle or drivers.
                                                            </p>
                                                        </div>
                                                    </main>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-wrapper">
                                            <div class="card-title-wrapper">
                                                <div class="card">
                                                    <main class="card-body">
                                                        <div class="body-wrapper">
                                                            <img draggable="false" class="img" src="../assets/service/open-service.svg" alt="Open">
                                                            <h4 class="heading">Open Auto<br> Transport</h4>
                                                            <div class="line"></div>
                                                            <p class="text">
                                                                Our open auto transport fleet consists of trailers open to weather elements creating the perfect blend of affordability, flexibility of shipping vehicles of all shapes & sizes, all while arriving safely & on time to it's destination.
                                                            </p>
                                                        </div>
                                                    </main>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-wrapper">
                                            <div class="card">
                                                <main class="card-body">
                                                    <div class="body-wrapper">
                                                        <img draggable="false" class="img" src="../assets/service/enclosed-service.svg" alt="Enclosed">
                                                        <h4 class="heading">Enclosed Auto<br> Transport</h4>
                                                        <div class="line"></div>
                                                        <p class="text">
                                                            Our enclosed auto transport fleet consists of meticulously maintained, climate-controlled trailers designed to shield your vehicle from the elements and road debris. This level of protection ensures that your prized possession arrives at its destination in the same pristine condition it left.
                                                        </p>
                                                    </div>
                                                </main>
                                            </div>
                                        </div>
                                        <div class="card-wrapper">
                                            <div class="card-title-wrapper">
                                                <div class="card">
                                                    <main class="card-body">
                                                        <div class="body-wrapper">
                                                            <img draggable="false" class="img" src="../assets/service/flexible-service.svg" alt="Open">
                                                            <h4 class="heading">Flexible prime<br> Expedited</h4>
                                                            <div class="line"></div>
                                                            <p class="text">
                                                                Choosing Flex is a standby service just like on the airlines, only available with another shipments cancelation or delay. Most affordable, but you don't have the luxury of having your shipment scheduled ahead of time, more last minute notice & not always available.
                                                            </p>
                                                        </div>
                                                    </main>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-wrapper">
                                            <div class="card-title-wrapper">
                                                <div class="card">
                                                    <main class="card-body">
                                                        <div class="body-wrapper">
                                                            <img draggable="false" class="img" src="../assets/service/prime-service.svg" alt="Open">
                                                            <h4 class="heading">Prime</h4>
                                                            <img draggable="false" class="popular-img" src="../assets/faqs/popular.webp" alt="popular">
                                                            <div class="line"></div>
                                                            <p class="text">
                                                                Prime service option is our featured and most popular transport service. Prime service is the  perfect blend of affordability, reliability and efficiency of time ensuring your vehicle arrives safely & on time to its destination.
                                                            </p>
                                                        </div>
                                                    </main>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-wrapper">
                                            <div class="card-title-wrapper">
                                                <div class="card">
                                                    <main class="card-body">
                                                        <div class="body-wrapper">
                                                            <img draggable="false" class="img" src="../assets/service/expidited-service.svg" alt="Open">
                                                            <h4 class="heading">Expidited</h4>
                                                            <div class="line"></div>
                                                            <p class="text">
                                                                You can choose expedited service option when you have urgent, time sensitive schedules. Your shipment is prioritized to the front of the line with team drivers upon availability. This allows your vehicle to be delivered faster then industry standard without jeopardizing the safety of your vehicle or drivers.
                                                            </p>
                                                        </div>
                                                    </main>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-wrapper">
                                            <div class="card-title-wrapper">
                                                <div class="card">
                                                    <main class="card-body">
                                                        <div class="body-wrapper">
                                                            <img draggable="false" class="img" src="../assets/service/open-service.svg" alt="Open">
                                                            <h4 class="heading">Open Auto<br> Transport</h4>
                                                            <div class="line"></div>
                                                            <p class="text">
                                                                Our open auto transport fleet consists of trailers open to weather elements creating the perfect blend of affordability, flexibility of shipping vehicles of all shapes & sizes, all while arriving safely & on time to it's destination.
                                                            </p>
                                                        </div>
                                                    </main>
                                                </div>
                                            </div>
                                        </div>
                                    <!-- </div> -->
                                </div>
                                
                            </div>
                        }
                        
                        @if (type !== 'mobile') {
                            <div class="types-wrapper" [@desktopServiceToggl]="{
                                value:serviceIndex,
                                params: {
                                    first: serviceOptions.positions.first,
                                    second: serviceOptions.positions.second,
                                    third: serviceOptions.positions.third,
                                    last: serviceOptions.positions.last,
                                    firstShake: serviceOptions.positions.firstShake,
                                    lastShake: serviceOptions.positions.lastShake,
                                    lastPlace: serviceOptions.lastServiceSection
                                },
                            }" #serviceSection (@desktopServiceToggl.start)="serviceAnimStart($event)" (@desktopServiceToggl.done)="serviceAnimFinish($event)">
                                @if(['tabletxl','ipadxl','mac','macxl','desktop'].includes(type)){
                                    <div class="title-conteiner">
                                        <h3 class="title">Transport Types</h3>
                                        <h3 class="title">Service Options</h3>
                                    </div>
                                }
                                <div id="types-conteiner" class="types-conteiner">
                                        <div class="card-wrapper">
                                            <div class="card-title-wrapper">
                                                <!-- <h3 class="title">Transport Types</h3> -->
                                                <div class="card">
                                                    <main class="card-body">
                                                        <div class="body-wrapper">
                                                            <img draggable="false" class="img" src="../assets/service/open-service.svg" alt="Open">
                                                            <h4 class="heading">Open Auto<br> Transport</h4>
                                                            <div class="line"></div>
                                                            <p class="text">
                                                                Our open auto transport fleet consists of trailers open to weather elements creating the perfect blend of affordability, flexibility of shipping vehicles of all shapes & sizes, all while arriving safely & on time to it's destination.
                                                            </p>
                                                        </div>
                                                    </main>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-wrapper">
                                            <div class="card">
                                                <main class="card-body">
                                                    <div class="body-wrapper">
                                                        <img draggable="false" class="img" src="../assets/service/enclosed-service.svg" alt="Enclosed">
                                                        <h4 class="heading">Enclosed Auto<br> Transport</h4>
                                                        <div class="line"></div>
                                                        <p class="text">
                                                            Our enclosed auto transport fleet consists of meticulously maintained, climate-controlled trailers designed to shield your vehicle from the elements and road debris. This level of protection ensures that your prized possession arrives at its destination in the same pristine condition it left.
                                                        </p>
                                                    </div>
                                                </main>
                                            </div>
                                        </div>
                                        <div class="arrow-wrapper">
                                            <div class="arrow-conteiner">
                                                <img draggable="false" class="arrow" src="../assets/service/shake-arrow.svg" alt="">
                                            </div>
                                        </div>
                                        <div class="card-wrapper">
                                            <div class="card-title-wrapper">
                                                <!-- <h3 class="title">Service Options</h3> -->
                                                <div class="card">
                                                    <main class="card-body">
                                                        <div class="body-wrapper">
                                                            <img draggable="false" class="img" src="../assets/service/flexible-service.svg" alt="Open">
                                                            <h4 class="heading">Flexible prime<br> Expedited</h4>
                                                            <div class="line"></div>
                                                            <p class="text">
                                                                Choosing Flex is a standby service just like on the airlines, only available with another shipments cancelation or delay. Most affordable, but you don't have the luxury of having your shipment scheduled ahead of time, more last minute notice & not always available.
                                                            </p>
                                                        </div>
                                                    </main>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-wrapper">
                                            <div class="card-title-wrapper">
                                                <!-- <h3 class="title">Service Options</h3> -->
                                                <div class="card">
                                                    <main class="card-body">
                                                        <div class="body-wrapper">
                                                            <img draggable="false" class="img" src="../assets/service/prime-service.svg" alt="Open">
                                                            <h4 class="heading">Prime</h4>
                                                            <img draggable="false" class="popular-img" src="../assets/faqs/popular.webp" alt="popular">
                                                            <div class="line"></div>
                                                            <p class="text">
                                                                Prime service option is our featured and most popular transport service. Prime service is the  perfect blend of affordability, reliability and efficiency of time ensuring your vehicle arrives safely & on time to its destination.
                                                            </p>
                                                        </div>
                                                    </main>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-wrapper">
                                            <div class="card-title-wrapper">
                                                <!-- <h3 class="title">Service Options</h3> -->
                                                <div class="card">
                                                    <main class="card-body">
                                                        <div class="body-wrapper">
                                                            <img draggable="false" class="img" src="../assets/service/expidited-service.svg" alt="Open">
                                                            <h4 class="heading">Expidited</h4>
                                                            <div class="line"></div>
                                                            <p class="text">
                                                                You can choose expedited service option when you have urgent, time sensitive schedules. Your shipment is prioritized to the front of the line with team drivers upon availability. This allows your vehicle to be delivered faster then industry standard without jeopardizing the safety of your vehicle or drivers.
                                                            </p>
                                                        </div>
                                                    </main>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        }
                </div>

                <div class="toggl-arrows">
                    <div class="arrow-wrapper">
                        <button tabindex="-1" class="arrow" (click)="serviceTogglFunc(-1)" >
                            <img draggable="false" class="img" src="../assets/Other/long-arrow.svg" alt="left">
                        </button>

                        <button tabindex="-1" class="arrow right" (click)="serviceTogglFunc(1)">
                            <img draggable="false" class="img" src="../assets/Other/long-arrow.svg" alt="right">
                        </button>
                    </div>
                </div>

               
            </main>
            <div class="option-tape-conteiner">
                <div class="gradient"></div>
                <div class="options-wrapper">
                    <!-- Clones for endless tape animation -->
                    <h2>Service Options</h2>
                    <img draggable="false" src="../assets/Other/Logo-white.svg" alt="HaulEx">
                    <h2>Service Options</h2>
                    <img draggable="false" src="../assets/Other/Logo-white.svg" alt="HaulEx">
                    <h2>Service Options</h2>
                    <img draggable="false" src="../assets/Other/Logo-white.svg" alt="HaulEx">
                    @if(['tabletxl','ipadxl','mac'].includes(type)){
                        <h2>Service Options</h2>
                        <img draggable="false" src="../assets/Other/Logo-white.svg" alt="HaulEx">
                        <h2>Service Options</h2>
                        <img draggable="false" src="../assets/Other/Logo-white.svg" alt="HaulEx">
                    }
                    @if(['macxl','desktop'].includes(type)){
                        <h2>Service Options</h2>
                        <img draggable="false" src="../assets/Other/Logo-white.svg" alt="HaulEx">
                        <h2>Service Options</h2>
                        <img draggable="false" src="../assets/Other/Logo-white.svg" alt="HaulEx">
                        <h2>Service Options</h2>
                        <img draggable="false" src="../assets/Other/Logo-white.svg" alt="HaulEx">
                        <h2>Service Options</h2>
                        <img draggable="false" src="../assets/Other/Logo-white.svg" alt="HaulEx">
                    }
                </div>
                <div class="gradient"></div>
            </div>
        </section>
    </div>

    <!-- Faqs -->
    <div class="section-wrapper" [@faqs]="selectedSection">
        <section [attr.data-disabled]="selectedSections.includes('faqs') ? true : false" [@faqsElements]='selectedSection' (@faqsElements.done)="animationFinished($event)" id="faqs" class="faqs">
            <main class="main">
                <div class="top">
                    <div class="text-wrapper">
                        <!-- <p class="text-image">
                            <img draggable="false" class="symbol" src="../assets/faqs/faq-symbol.svg" alt="">
                            <span>FAG`s: answers to questions. Contact us if necessary.</span>
                            <img draggable="false" class="symbol mirror" src="../assets/faqs/faq-symbol.svg" alt="">
                        </p> -->
                        <h2 class="title">faq`s</h2>
                    </div>
                    <div (click)="goToQuote()" id="getQuote" class="getAQuote-wrapper getA">
                        <img draggable="false" loading='lazy' class="circle" src="../assets/Other/GetAQuote-circle.svg" alt="GetAQuote">
                        <img draggable="false" loading='lazy' class="arrow" src="../assets/Other/short-arrow.svg" alt="arrow">
                    </div>
                </div>
                <ul class="faqs-list">
                    <!-- This version of faq`s is showing only on mobile -->
                    @if(['mobile'].includes(type)){
                        <div class="list-conteiner">
                            @if (showFaqCard('1')) {
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqPopH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="1"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('1')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                            @if(showFaqCard('4')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="4"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('4')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                            @if(showFaqCard('7')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="7"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('7')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                            @if(showFaqCard('ten')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="10"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('10')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                        </div>
                        <div class="list-conteiner">
                            @if(showFaqCard('2')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="2"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('2')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                            @if(showFaqCard('5')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="5"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('5')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                            @if(showFaqCard('8')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="8"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('8')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                            @if(showFaqCard('elev')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="11"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('11')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                        </div>
                        <div class="list-conteiner">
                            @if(showFaqCard('3')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="3"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('3')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                            @if(showFaqCard('6')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="6"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('6')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                [minText]="true"
                                >
                                </faqs-card>
                            }
                            @if(showFaqCard('9')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="9"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('9')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                [minText]="true"
                                >
                                </faqs-card>
                            }
                            @if(showFaqCard('twel')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="12"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('12')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                [minText]="true"
                                >
                                </faqs-card>
                            }
                        </div>
                    }
                    <!-- Tablet, Ipad -->
                    @if(['tablet','ipad'].includes(type)){
                        <div class="list-conteiner">
                            @if (showFaqCard('1')) {
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqPopH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="1"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('1')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                            @if(showFaqCard('9')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="9"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('9')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                        </div>
                        <div class="list-conteiner">
                            
                            @if(showFaqCard('elev')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="11"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('11')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                            @if(showFaqCard('6')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="6"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('6')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                        </div>
                        <div class="list-conteiner">
                            @if(showFaqCard('twel')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="12"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('12')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                            @if(showFaqCard('7')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="7"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('7')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                        </div>
                        <div class="list-conteiner">
                            @if(showFaqCard('8')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="8"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('8')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                [minText]="true"
                                >
                                </faqs-card>
                            }
                           
                            
                            @if(showFaqCard('ten')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="10"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('10')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                        </div>
                        <div class="list-conteiner">
                           
                            @if(showFaqCard('2')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="2"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('2')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                        </div>
                        <div class="list-conteiner">
                            @if(showFaqCard('3')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="3"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('3')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                        </div>
                        <div class="list-conteiner">
                            @if(showFaqCard('4')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="4"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('4')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                        </div>
                        <div class="list-conteiner">
                            @if(showFaqCard('5')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="5"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('5')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                        </div>
                    }
                    <!-- Tabletxl,Ipadxl,mac,macxl,desktop -->
                    @if(!(['tablet','ipad','mobile']).includes(type)){
                        <div class="list-conteiner">
                            @if(showFaqCard('7')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="7"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('7')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                            @if (showFaqCard('1')) {
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqPopH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="1"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('1')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                            
                        </div>
                        <div class="list-conteiner">
                            @if(showFaqCard('8')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="8"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('8')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                [minText]="true"
                                >
                                </faqs-card>
                            }
                            @if(showFaqCard('elev')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="11"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('11')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                        </div>
                        <div class="list-conteiner">
                            @if(showFaqCard('9')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqPopH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="9"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('9')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                [minText]="true"
                                >
                                </faqs-card>
                            }
                            @if(showFaqCard('twel')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="12"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('12')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                        </div>
                        <div class="list-conteiner">
                            
                            
                            @if(showFaqCard('ten')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqPopH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="10"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('10')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                        </div>
                        <div class="list-conteiner">
                            
                            @if(showFaqCard('2')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="2"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('2')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                        </div>
                        <div class="list-conteiner">
                            
                            @if(showFaqCard('3')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="3"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('3')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                        </div>
                        <div class="list-conteiner">
                            
                            @if(showFaqCard('4')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="4"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('4')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                        </div>
                        <div class="list-conteiner">
                            
                            @if(showFaqCard('5')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="5"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('5')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                        </div>
                        <div class="list-conteiner">
                            
                            @if(showFaqCard('6')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="6"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('6')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                        </div>
                    }
                </ul>
                <div class="bottom">
                    @if(faqSectionIndex !== maxFaqSecInd){
                        <button tabindex="-1" (click)="morelessFaqs(1)" class="more button">
                            More
                            <img draggable="false" class="img" src="../assets/Other/chevron-white.svg" alt="">
                        </button>
                    }
                    @if(faqSectionIndex !== 1){
                        <button tabindex="-1" (click)="morelessFaqs(-1)" class="less button" [ngClass]="{
                            'less-margin': maxFaqSecInd !== faqSectionIndex
                        }">
                            <img draggable="false" class="img" src="../assets/Other/chevron-white.svg" alt="">
                        </button>
                    }
                </div>
            </main>
        </section>
    </div>

    

    <!-- Careers -->
    <div class="section-wrapper" [@careers]="selectedSection">
    <section [attr.data-disabled]="selectedSections.includes('careers') ? true : false" [@careersElements]="selectedSection" (@careersElements.done)="animationFinished($event)" id="careers" class="careers">
        <div class="gradient gradient1"></div>
        <main class="main">
            <div class="content">
                <h2 class="title">Careers in auto @if(type ==='ipad'){<br>}transport</h2>
                <span class="sub-text">Join The Best Team In The Industry</span>
                <button tabindex="-1" class="button"
                routerLink="/cand-form"
                >Im interested</button>
            </div>
        </main>
        <div class="gradient gradient2"></div>
    </section>
    </div>

    <!-- Reviews -->
    <div class="section-wrapper" [@reviews]="selectedSection">
    <section [attr.data-disabled]="selectedSections.includes('reviews') ? true : false" [@reviewsElements]="selectedSection" (@reviewsElements.done)="animationFinished($event)" id="reviews" class="reviews">
        <main class="main">
            <h2 class="title">
                Customer Who`ve @if (['mobile','tablet','ipad'].includes(type)) {<br>} shared their
                <p>experience with us.</p>
            </h2>
            <div class="tape_wrapper">
                <div class="tape-conteiner">
                    <div data-place="first" class="tape" (click)="togglReviewsAnimation($event,'first')" >
                    
                    <!-- Original -->
                    <div class="review_card">
                        <div class="main-sec">
                            <img draggable="false" loading="lazy" class="logo" src="../assets/reviews/review1(Dino)100.webp" alt="Dino Dzonov.">

                            <span class="text">
                                I had an exceptional experience with Coast to Coast car chipping company! From start to finish, their service was top-notch and exceeded my expectations.. 
                                <a tabindex="-1" class="gray" title='Google review' title="Google review" target="_blank" href="https://www.google.com/maps/contrib/112434592579501595538/place/ChIJXw1ja7xarlQRQQPZb0Gfer4/@45.6901033,-122.5443155,17z" class="gray">More</a>
                            </span>
                        </div>
                        <p class="post">
                            Dino Dzonov
                        </p>

                        
                    </div>

                    <div class="review_card">
                        <div class="main-sec">
                            <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review2(George)100.webp" alt="George Isahakyan">
                            <span class="text">
                                What a smooth experience getting my truck transported from San Antonio to Greenville SC. Everything was clear and well communicated throughout the entire.. 
                                <a tabindex="-1" class="gray" title='Google review' target="_blank" href="https://www.google.com/maps/contrib/107104082844165896938/place/ChIJXw1ja7xarlQRQQPZb0Gfer4/@40.0515062,-109.5782858,4z" class="gray">More</a>
                            </span>
                        </div>
                        <p class="post">
                            George Isahakyan
                        </p>
                    </div>

                    <div class="review_card">
                        <div class="main-sec">
                            <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review3(Abby)100.webp" alt="Abby Chin">

                            <span class="text">
                                Coast to coast is not a scam! I needed to get my car back home after wrapping up a training year on the west coast, and they worked with me to..
                                <a tabindex="-1" class="gray" title='Google review' target="_blank" href="https://www.google.com/maps/contrib/100052392959755497035/place/ChIJXw1ja7xarlQRQQPZb0Gfer4/@45.690107,-122.5443155,16z" class="gray">More</a>
                            </span>
                        </div>
                        <p class="post">
                            Abby Chin
                        </p>
                    </div>

                    <div class="review_card">
                        <div class="main-sec">
                            <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review4(Marlon)100.webp" alt="Marlon Mitchell">

                            <span class="text">
                                I contracted with Coast to Coast to transport my Porsche from the Seattle, WA area, to Raleigh, NC. It was my first time shipping..
                                <a tabindex="-1" class="gray" title='Google review' href="https://www.google.com/maps/contrib/115757187572458676982/place/ChIJXw1ja7xarlQRQQPZb0Gfer4/@36.5250211,-108.0252059,4z" target="_blank">More</a>
                            </span>
                        </div>
                        <p class="post">
                            Marlon Mitchell
                        </p>
                    </div>

                    <div class="review_card">
                        <div class="main-sec">
                            <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review5(Rico)100.webp" alt="Rico Man">

                            <span class="text">
                                OMG..these guys are GREAT..!! I spent 2 weeks dealing with the "other" transporters.....almost all were just a clearing house that sends your name out and..
                                <a tabindex="-1" class="gray" title='Google review' href="https://www.google.com/maps/contrib/117622850550917280151/place/ChIJXw1ja7xarlQRQQPZb0Gfer4/@42.909955,-115.3847798,5z" target="_blank">More</a>
                            </span>
                        </div>
                        <p class="post">
                            Rico Man
                        </p>
                    </div>

                    <div class="review_card">
                        <div class="main-sec">
                            <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review6(Joe)100.webp" alt="Joe Letts">

                            <span class="text">
                                Quick delivery van showed up in one piece same condition as the photos if I need to I'll use these people again Quick Service quick..
                                <a tabindex="-1" class="gray" title='Google review' href="https://www.google.com/maps/contrib/101663638275659956212/place/ChIJXw1ja7xarlQRQQPZb0Gfer4/@45.690107,-122.5443155,16z" target="_blank">More</a>
                            </span>
                        </div>
                        <p class="post">
                            Joe Letts
                        </p>
                    </div>

                    <div class="review_card">
                        <div class="main-sec">
                            <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review7(Rene)100.webp" alt="Rene S">
  
                            <span class="text">
                                This is my first time to transport a car and have no any idea how the process goes. I first encountered brokers during my initial search and was never..
                                <a tabindex="-1" class="gray" title='Google review' href="https://www.google.com/maps/contrib/111390081939062002555/place/ChIJXw1ja7xarlQRQQPZb0Gfer4/@37.483746,-111.0465781,5z" target="_blank">More</a>
                            </span>
                        </div>
                        <p class="post">
                            Rene S
                        </p>
                    </div>

                    <div class="review_card">
                        <div class="main-sec">
                            <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review8(Patricia)100.webp" alt="Patricia Barnes">
   
                            <span class="text">
                                Had a very positive experience with Coast to Coast moving a car from Florida to Idaho.  Very straight forward  and up front on price..
                                <a tabindex="-1" class="gray" title='Google review' href="https://www.google.com/maps/contrib/106907921804108660558/place/ChIJXw1ja7xarlQRQQPZb0Gfer4/@44.3656372,-120.5112933,7z" target="_blank">More</a>
                            </span>
                        </div>
                        <p class="post">
                            Patricia Barnes
                        </p>
                    </div>

                    <div class="review_card">
                        <div class="main-sec">
                            <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review9(John)100.webp" alt="John Culbreth">

                            <span class="text">
                                Five Star Rating  -  no question  about it.  Based on a local recommendation, I selected Coast to Coast Auto Transport to ship my car from..
                                <a tabindex="-1" class="gray" title='Google review' href="https://www.google.com/maps/contrib/100066753435620260508/place/ChIJXw1ja7xarlQRQQPZb0Gfer4/@36.2652642,-109.7190329,5z" target="_blank">More</a>
                            </span>
                        </div>
                        <p class="post">
                            John Culbreth
                        </p>
                    </div>

                    <div class="review_card">
                        <div class="main-sec">
                            <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review10(Cujo)100.webp" alt="Cujo">

                            <span class="text">
                                Customer service is outstanding! Arranged for vehicle to be moved from California to Washington. Had to change pick-up location and that was not a problem at all..
                                <a tabindex="-1" class="gray" title='Google review' href="https://www.google.com/maps/contrib/113716753908876449274/place/ChIJXw1ja7xarlQRQQPZb0Gfer4/@47.4383415,-124.127765,7z" target="_blank">More</a>
                            </span>
                        </div>
                        <p class="post">
                            Cujo
                        </p>
                    </div>

                    <!-- Copy for animation -->
                    
                    <div class="review_card">
                        <div class="main-sec">
                            <img draggable="false" loading="lazy" class="logo" src="../assets/reviews/review1(Dino)100.webp" alt="Dino Dzonov.">

                            <span class="text">
                                I had an exceptional experience with Coast to Coast car chipping company! From start to finish, their service was top-notch and exceeded my expectations.. 
                                <a tabindex="-1" class="gray" title='Google review' target="_blank" href="https://www.google.com/maps/contrib/112434592579501595538/place/ChIJXw1ja7xarlQRQQPZb0Gfer4/@45.6901033,-122.5443155,17z" class="gray">More</a>
                            </span>
                        </div>
                        <p class="post">
                            Dino Dzonov
                        </p>
                    </div>

                    <div class="review_card">
                        <div class="main-sec">
                            <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review2(George)100.webp" alt="George Isahakyan">

                            <span class="text">
                                What a smooth experience getting my truck transported from San Antonio to Greenville SC. Everything was clear and well communicated throughout the entire.. 
                                <a tabindex="-1" target="_blank" href="https://www.google.com/maps/contrib/107104082844165896938/place/ChIJXw1ja7xarlQRQQPZb0Gfer4/@40.0515062,-109.5782858,4z" class="gray">More</a>
                            </span>
                        </div>
                        <p class="post">
                            George Isahakyan
                        </p>
                    </div>

                    <div class="review_card">
                        <div class="main-sec">
                            <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review3(Abby)100.webp" alt="Abby Chin">

                            <span class="text">
                                Coast to coast is not a scam! I needed to get my car back home after wrapping up a training year on the west coast, and they worked with me to..
                                <a tabindex="-1" class="gray" title='Google review' target="_blank" href="https://www.google.com/maps/contrib/100052392959755497035/place/ChIJXw1ja7xarlQRQQPZb0Gfer4/@45.690107,-122.5443155,16z" class="gray">More</a>
                            </span>
                        </div>
                        <p class="post">
                            Abby Chin
                        </p>
                    </div>

                    <div class="review_card">
                        <div class="main-sec">
                            <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review4(Marlon)100.webp" alt="Marlon Mitchell">

                            <span class="text">
                                I contracted with Coast to Coast to transport my Porsche from the Seattle, WA area, to Raleigh, NC. It was my first time shipping..
                                <a tabindex="-1" class="gray" title='Google review' href="https://www.google.com/maps/contrib/115757187572458676982/place/ChIJXw1ja7xarlQRQQPZb0Gfer4/@36.5250211,-108.0252059,4z" target="_blank">More</a>
                            </span>
                        </div>
                        <p class="post">
                            Marlon Mitchell
                        </p>
                    </div>

                    <div class="review_card">
                        <div class="main-sec">
                            <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review5(Rico)100.webp" alt="Rico Man">

                            <span class="text">
                                OMG..these guys are GREAT..!! I spent 2 weeks dealing with the "other" transporters.....almost all were just a clearing house that sends your name out and..
                                <a tabindex="-1" class="gray" title='Google review' href="https://www.google.com/maps/contrib/117622850550917280151/place/ChIJXw1ja7xarlQRQQPZb0Gfer4/@42.909955,-115.3847798,5z" target="_blank">More</a>
                            </span>
                        </div>
                        <p class="post">
                            Rico Man
                        </p>
                    </div>

                    <div class="review_card">
                        <div class="main-sec">
                            <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review6(Joe)100.webp" alt="Joe Letts">

                            <span class="text">
                                Quick delivery van showed up in one piece same condition as the photos if I need to I'll use these people again Quick Service quick..
                                <a tabindex="-1" class="gray" title='Google review' href="https://www.google.com/maps/contrib/101663638275659956212/place/ChIJXw1ja7xarlQRQQPZb0Gfer4/@45.690107,-122.5443155,16z" target="_blank">More</a>
                            </span>
                        </div>
                        <p class="post">
                            Joe Letts
                        </p>
                    </div>

                    <div class="review_card">
                        <div class="main-sec">
                            <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review7(Rene)100.webp" alt="Rene S">

                            <span class="text">
                                This is my first time to transport a car and have no any idea how the process goes. I first encountered brokers during my initial search and was never..
                                <a tabindex="-1" class="gray" title='Google review' href="https://www.google.com/maps/contrib/111390081939062002555/place/ChIJXw1ja7xarlQRQQPZb0Gfer4/@37.483746,-111.0465781,5z" target="_blank">More</a>
                            </span>
                        </div>
                        <p class="post">
                            Rene S
                        </p>
                    </div>

                    <div class="review_card">
                        <div class="main-sec">
                            <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review8(Patricia)100.webp" alt="Patricia Barnes">

                            <span class="text">
                                Had a very positive experience with Coast to Coast moving a car from Florida to Idaho.  Very straight forward  and up front on price..
                                <a tabindex="-1" class="gray" title='Google review' href="https://www.google.com/maps/contrib/106907921804108660558/place/ChIJXw1ja7xarlQRQQPZb0Gfer4/@44.3656372,-120.5112933,7z" target="_blank">More</a>
                            </span>
                        </div>
                        <p class="post">
                            Patricia Barnes
                        </p>
                    </div>

                    <div class="review_card">
                        <div class="main-sec">
                            <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review9(John)100.webp" alt="John Culbreth">

                            <span class="text">
                                Five Star Rating  -  no question  about it.  Based on a local recommendation, I selected Coast to Coast Auto Transport to ship my car from..
                                <a tabindex="-1" class="gray" title='Google review' href="https://www.google.com/maps/contrib/100066753435620260508/place/ChIJXw1ja7xarlQRQQPZb0Gfer4/@36.2652642,-109.7190329,5z" target="_blank">More</a>
                            </span>
                        </div>
                        <p class="post">
                            John Culbreth
                        </p>
                    </div>

                    <div class="review_card">
                        <div class="main-sec">
                            <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review10(Cujo)100.webp" alt="Cujo">

                            <span class="text">
                                Customer service is outstanding! Arranged for vehicle to be moved from California to Washington. Had to change pick-up location and that was not a problem at all..
                                <a tabindex="-1" class="gray" title='Google review' href="https://www.google.com/maps/contrib/113716753908876449274/place/ChIJXw1ja7xarlQRQQPZb0Gfer4/@47.4383415,-124.127765,7z" target="_blank">More</a>
                            </span>
                        </div>
                        <p class="post">
                            Cujo
                        </p>
                    </div>

                </div>
                    
                </div>
                <div class="tape-conteiner">
                    <div data-place="second" class="tape" (click)="togglReviewsAnimation($event,'second')">
                        <!-- Original -->
                        <div class="review_card">
                            <div class="main-sec">
                                <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review11(Casey)100.webp" alt="Casey Manley">

                                <span class="text">
                                    Coast to Coast was absolutely excellent from start to finish. I found them through a broker and I could not be happier. Their customer service was absolutely..
                                    <a tabindex="-1" class="gray" title='Google review' href="https://www.google.com/maps/contrib/113740104696568880795/place/ChIJXw1ja7xarlQRQQPZb0Gfer4/@43.4387675,-106.7872291,4z" target="_blank">More</a>
                                </span>
                            </div>
                            <p class="post">
                                Casey Manley
                            </p>
                        </div>

                        <div class="review_card">
                            <div class="main-sec">
                                <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review12(Smeyer)100.webp" alt="Smeyer">

                                <span class="text">
                                    My brand new 2018 Pacifica was picked up in CO as promised and delivered to MI undamaged. Customer service was excellent, start to finish, and the price was very..
                                    <a tabindex="-1" class="gray" title='Google review' href="https://www.google.com/maps/contrib/104982161526034459663/place/ChIJXw1ja7xarlQRQQPZb0Gfer4/@42.590755,-116.8432573,6z" target="_blank">More</a>
                                </span>
                            </div>
                            <p class="post">
                                Smeyer
                            </p>
                        </div>

                        <div class="review_card">
                            <div class="main-sec">
                                <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review13(Gary)100.webp" alt="Gary Briggs">

                                <span class="text">
                                    Great experience. We shipped a Ford Explorer from Huntington Beach CA to Cape Cod MA. The Driver picked up the car in front of our house in HB and when..
                                    <a tabindex="-1" class="gray" title='Google review' href="https://www.google.com/maps/contrib/105721521493967785915/place/ChIJXw1ja7xarlQRQQPZb0Gfer4/@29.443764,-102.8576609,4z" target="_blank">More</a>
                                </span>
                            </div>
                            <p class="post">
                                Gary Briggs
                            </p>
                        </div>

                        <div class="review_card">
                            <div class="main-sec">
                                <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review14(Stephen)100.webp" alt="Stephen Brown">

                                <span class="text">
                                    Coast to Coast is the best auto transport company I’ve ever contracted to move a classic car across the country. Ben went out of his way to..
                                    <a tabindex="-1" class="gray" title='Google review' href="https://www.google.com/maps/contrib/111346400697548829975/place/ChIJXw1ja7xarlQRQQPZb0Gfer4/@44.6259856,-120.5114981,7z" target="_blank">More</a>
                                </span>
                            </div>
                            <p class="post">
                                Stephen Brown
                            </p>
                        </div>

                        <div class="review_card">
                            <div class="main-sec">
                                <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review15(Jim)100.webp" alt="Jim B">

                                <span class="text">
                                    After researching trucking and transport companies, I chose Coast to Coast Auto Transport to ship my newly purchase 2015 Infiniti from Seattle Washington to Loudoun County Virginia..
                                    <a tabindex="-1" class="gray" title='Google review' href="https://www.google.com/maps/contrib/115104007849789434662/place/ChIJXw1ja7xarlQRQQPZb0Gfer4/@45.6901033,-133.0886156,5z" target="_blank">More</a>
                                </span>
                            </div>
                            <p class="post">
                                Jim B
                            </p>
                        </div>

                        <div class="review_card">
                            <div class="main-sec">
                                <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review16(Kaylee)100.webp" alt="Kaylee Karel">

                                <span class="text">
                                    Coast to Coast transported my car from Idaho to Virginia when I moved. My flight was delayed an entire day and the driver went out of his way to..
                                    <a tabindex="-1" class="gray" title='Google review' href="https://www.google.com/maps/contrib/108874500634613312994/place/ChIJXw1ja7xarlQRQQPZb0Gfer4/@41.6908753,-107.4238773,4z" target="_blank">More</a>
                                </span>
                            </div>
                            <p class="post">
                                Kaylee Karel
                            </p>
                        </div>

                        <div class="review_card">
                            <div class="main-sec">
                                <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review17(Bill)100.webp" alt="Bill Barnhart">
 
                                <span class="text">
                                    Excellent experience with Coast-to-Coast Auto Transport.  Tacoma, WA to Victor, NY.  Done on time without any damage to vehicle.  Driver and Dispatcher very courteous..
                                    <a tabindex="-1" class="gray" title='Google review' href="https://www.google.com/maps/contrib/108588850447165773311/place/ChIJXw1ja7xarlQRQQPZb0Gfer4/@40.432358,-118.3395638,5z" target="_blank">More</a>
                                </span>
                            </div>
                            <p class="post">
                                Bill Barnhart
                            </p>
                        </div>

                        <div class="review_card">
                            <div class="main-sec">
                                <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review18(Peter)100.webp" alt="Peter Speck">

                                <span class="text">
                                    A great company to work with! They transported my car from New York to Iowa all within the promised window, and kept in good communication through the entire..
                                    <a tabindex="-1" class="gray" title='Google review' href="https://www.google.com/maps/contrib/102773558581589847831/place/ChIJXw1ja7xarlQRQQPZb0Gfer4/@43.3388111,-107.7991265,4z" target="_blank">More</a>
                                </span>
                            </div>
                            <p class="post">
                                Peter Speck
                            </p>
                        </div>

                        <div class="review_card">
                            <div class="main-sec">
                                <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review19(Bessie)100.webp" alt="Bessie H">

                                <span class="text">
                                    This was my first time using an auto shipping/trucking company and I had an excellent experience!! Hunter kept me informed every step of the way and answered..
                                    <a tabindex="-1" class="gray" title='Google review' href="https://www.google.com/maps/contrib/116229921732873488087/place/ChIJXw1ja7xarlQRQQPZb0Gfer4/@45.6901033,-133.0886156,5z" target="_blank">More</a>
                                </span>
                            </div>
                            <p class="post">
                                Bessie H
                            </p>
                        </div>

                        <div class="review_card">
                            <div class="main-sec">
                                <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review20(Santiago)100.webp" alt="Santiago Perez">

                                <span class="text">
                                    Great company to deal with that keeps you up to date on your vehicle delivery at any time you request. Always accessible and they went out of their way..
                                    <a tabindex="-1" class="gray" title='Google review' href="https://www.google.com/maps/contrib/105285070697789668651/place/ChIJXw1ja7xarlQRQQPZb0Gfer4/@33.783989,-82.6153243,3z" target="_blank">More</a>
                                </span>
                            </div>
                            <p class="post">
                                Santiago Perez
                            </p>
                        </div>

                        <!-- Copy for animation -->

                        <div class="review_card">
                            <div class="main-sec">
                                <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review11(Casey)100.webp" alt="Casey Manley">
 
                                <span class="text">
                                    Coast to Coast was absolutely excellent from start to finish. I found them through a broker and I could not be happier. Their customer service was absolutely..
                                    <a tabindex="-1" class="gray" title='Google review' href="https://www.google.com/maps/contrib/113740104696568880795/place/ChIJXw1ja7xarlQRQQPZb0Gfer4/@43.4387675,-106.7872291,4z" target="_blank">More</a>
                                </span>
                            </div>
                            <p class="post">
                                Casey Manley
                            </p>
                        </div>

                        <div class="review_card">
                            <div class="main-sec">
                                <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review12(Smeyer)100.webp" alt="Smeyer">

                                <span class="text">
                                    My brand new 2018 Pacifica was picked up in CO as promised and delivered to MI undamaged. Customer service was excellent, start to finish, and the price was very..
                                    <a tabindex="-1" class="gray" title='Google review' href="https://www.google.com/maps/contrib/104982161526034459663/place/ChIJXw1ja7xarlQRQQPZb0Gfer4/@42.590755,-116.8432573,6z" target="_blank">More</a>
                                </span>
                            </div>
                            <p class="post">
                                Smeyer
                            </p>
                        </div>

                        <div class="review_card">
                            <div class="main-sec">
                                <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review13(Gary)100.webp" alt="Gary Briggs">

                                <span class="text">
                                    Great experience. We shipped a Ford Explorer from Huntington Beach CA to Cape Cod MA. The Driver picked up the car in front of our house in HB and when..
                                    <a tabindex="-1" class="gray" title='Google review' href="https://www.google.com/maps/contrib/105721521493967785915/place/ChIJXw1ja7xarlQRQQPZb0Gfer4/@29.443764,-102.8576609,4z" target="_blank">More</a>
                                </span>
                            </div>
                            <p class="post">
                                Gary Briggs
                            </p>
                        </div>

                        <div class="review_card">
                            <div class="main-sec">
                                <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review14(Stephen)100.webp" alt="Stephen Brown">

                                <span class="text">
                                    Coast to Coast is the best auto transport company I’ve ever contracted to move a classic car across the country. Ben went out of his way to..
                                    <a tabindex="-1" class="gray" title='Google review' href="https://www.google.com/maps/contrib/111346400697548829975/place/ChIJXw1ja7xarlQRQQPZb0Gfer4/@44.6259856,-120.5114981,7z" target="_blank">More</a>
                                </span>
                            </div>
                            <p class="post">
                                Stephen Brown
                            </p>
                        </div>

                        <div class="review_card">
                            <div class="main-sec">
                                <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review15(Jim)100.webp" alt="Jim B">

                                <span class="text">
                                    After researching trucking and transport companies, I chose Coast to Coast Auto Transport to ship my newly purchase 2015 Infiniti from Seattle Washington to Loudoun County Virginia..
                                    <a tabindex="-1" class="gray" title='Google review' href="https://www.google.com/maps/contrib/115104007849789434662/place/ChIJXw1ja7xarlQRQQPZb0Gfer4/@45.6901033,-133.0886156,5z" target="_blank">More</a>
                                </span>
                            </div>
                            <p class="post">
                                Jim B
                            </p>
                        </div>

                        <div class="review_card">
                            <div class="main-sec">
                                <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review16(Kaylee)100.webp" alt="Kaylee Karel">

                                <span class="text">
                                    Coast to Coast transported my car from Idaho to Virginia when I moved. My flight was delayed an entire day and the driver went out of his way to..
                                    <a tabindex="-1" class="gray" title='Google review' href="https://www.google.com/maps/contrib/108874500634613312994/place/ChIJXw1ja7xarlQRQQPZb0Gfer4/@41.6908753,-107.4238773,4z" target="_blank">More</a>
                                </span>
                            </div>
                            <p class="post">
                                Kaylee Karel
                            </p>
                        </div>

                        <div class="review_card">
                            <div class="main-sec">
                                <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review17(Bill)100.webp" alt="Bill Barnhart">
 
                                <span class="text">
                                    Excellent experience with Coast-to-Coast Auto Transport.  Tacoma, WA to Victor, NY.  Done on time without any damage to vehicle.  Driver and Dispatcher very courteous..
                                    <a tabindex="-1" class="gray" title='Google review' href="https://www.google.com/maps/contrib/108588850447165773311/place/ChIJXw1ja7xarlQRQQPZb0Gfer4/@40.432358,-118.3395638,5z" target="_blank">More</a>
                                </span>
                            </div>
                            <p class="post">
                                Bill Barnhart
                            </p>
                        </div>

                        <div class="review_card">
                            <div class="main-sec">
                                <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review18(Peter)100.webp" alt="Peter Speck">

                                <span class="text">
                                    A great company to work with! They transported my car from New York to Iowa all within the promised window, and kept in good communication through the entire..
                                    <a tabindex="-1" class="gray" title='Google review' href="https://www.google.com/maps/contrib/102773558581589847831/place/ChIJXw1ja7xarlQRQQPZb0Gfer4/@43.3388111,-107.7991265,4z" target="_blank">More</a>
                                </span>
                            </div>
                            <p class="post">
                                Peter Speck
                            </p>
                        </div>

                        <div class="review_card">
                            <div class="main-sec">
                                <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review19(Bessie)100.webp" alt="Bessie H">

                                <span class="text">
                                    This was my first time using an auto shipping/trucking company and I had an excellent experience!! Hunter kept me informed every step of the way and answered..
                                    <a tabindex="-1" class="gray" title='Google review' href="https://www.google.com/maps/contrib/116229921732873488087/place/ChIJXw1ja7xarlQRQQPZb0Gfer4/@45.6901033,-133.0886156,5z" target="_blank">More</a>
                                </span>
                            </div>
                            <p class="post">
                                Bessie H
                            </p>
                        </div>

                        <div class="review_card">
                            <div class="main-sec">
                                <img draggable="false" loading='lazy' class="logo" src="../assets/reviews/review20(Santiago)100.webp" alt="Santiago Perez">

                                <span class="text">
                                    Great company to deal with that keeps you up to date on your vehicle delivery at any time you request. Always accessible and they went out of their way..
                                    <a tabindex="-1" class="gray" title='Google review' href="https://www.google.com/maps/contrib/105285070697789668651/place/ChIJXw1ja7xarlQRQQPZb0Gfer4/@33.783989,-82.6153243,3z" target="_blank">More</a>
                                </span>
                            </div>
                            <p class="post">
                                Santiago Perez
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </section>
    </div>

    <!-- Footer -->
    <div class="section-wrapper" [@footer]="selectedSection">
    <footer [attr.data-disabled]="selectedSections.includes('footer') ? true : false" [@footerElements]="selectedSection" (@footerElements.done)="animationFinished($event)" id="footer" class="footer">
        <main class="main">
            <div class="first-wrapper">
                <img draggable="false" class="logo"  src="../assets/Other/C2C-white.svg" alt="C2C logo">
                <div class="text-wrapper">
                    <h1 class="title">
                        Coast To Coast Is Your Best Business Partner.
                    </h1>
                    <h2 class="sub-text">
                        Our focus Is simply to build the best auto transport experience for thousands of customers & drivers daily!
                    </h2>
                </div>
                <div class="email-wrapper">
                    <div class="email-conteiner">
                        <input tabindex="-1" autocomplete="off" (focus)="question.classList.remove('success');" #question [(ngModel)]="messageContent" type="text" name="questions" id="question-input"
                        placeholder="Say hello, or ask your question here..."
                        class="input"
                        >
                        <button tabindex="-1" (click)="postMessage()" class="button">
                            Send
                        </button>
                    </div>  
                </div>
            </div>
            <div class="second-wrapper">
                <div (click)="goToQuote()" id="getQuote" class="getAQuote-wrapper getA">
                    <img draggable="false" loading='lazy' class="circle" src="../assets/Other/GetAQuote-circle.svg" alt="GetAQuote">
                    <img draggable="false" loading='lazy' class="arrow" src="../assets/Other/short-arrow.svg" alt="arrow">
                </div>
                <div class="bottom">
                    <span class="license">
                        Coast To Coast © {{year}}
                    </span>
                    <div (click)="backToTop()" class="backtotop">
                        <img draggable="false" class="img" src="../assets/Other/trending-up.svg" alt="">
                        <span class="text">Back To The Top</span>
                    </div>
                </div>
            </div>
            
        </main>
    </footer>
    </div>
</div>
    