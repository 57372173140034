import { NgModule } from '@angular/core';
import { FormsModule }   from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';


import {Routes, RouterModule} from '@angular/router';

import { AppComponent } from './app.component';
import {TermsComponent} from './terms-of-use/terms.component'

import { LandingComponent} from './main-page/landing.component'
import { FaqsCard } from './main-page/faqs-content/faqs-card.component'


import {QuoteComponent} from './quote-form/quote-form.component'
import {CandidateComponent} from './cand-form/cand-form.component'

const appRoutes: Routes = [
  {path: '',component: LandingComponent, data: {animationState: 'Landing'}},
  {path: 'terms-of-use',component: TermsComponent},
  {path: 'quote-form',component: QuoteComponent, data: {animationState: 'Page'}},
  {path: 'cand-form',component: CandidateComponent, data: {animationState: 'Page'}},
  {path: '**', redirectTo: ''}
]

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    RouterModule.forRoot(appRoutes)
  ],
  declarations: [AppComponent,TermsComponent,LandingComponent,QuoteComponent, CandidateComponent,FaqsCard],
  bootstrap: [AppComponent ],
  providers: [ ]
})
export class AppModule { }